<template>
  <ul class="nav-items push">
    <slot/>
  </ul>
</template>

<script>
export default {
  data () {
    return {
      items: []
    }
  }
}
</script>
