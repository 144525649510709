const fields = [
  {
    key: 'name',
    type: 'input',
    templateOptions: {
      label: 'name'
    }
  }
]

export default fields
