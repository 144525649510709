import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import 'vue-multiselect/dist/vue-multiselect.min.css'
import 'flag-icon-css/css/flag-icon.css'
import 'vue-ctk-date-time-picker/dist/vue-ctk-date-time-picker.css'
import 'vue2-dropzone/dist/vue2Dropzone.min.css'
import RegisterPlugin from './components/register'
import ApiService from './services/api.service'
import { TokenService } from './services/storage.service'
import i18n from './i18n'
import UserService from './services/user.service'
import VueFormWizard from 'vue-form-wizard'

import 'vue-form-wizard/dist/vue-form-wizard.min.css'
import 'vue-select/dist/vue-select.css'
import vClickOutside from 'v-click-outside'
import 'vue-advanced-cropper/dist/style.css'
import 'vue-advanced-cropper/dist/theme.compact.css'
import 'vue-swatches/dist/vue-swatches.css'

// Import FilePond styles
require('./oneui/app.js')
require('./assets/sass/main.scss')

Vue.config.productionTip = false
Vue.use(vClickOutside)
Vue.use(RegisterPlugin)
Vue.use(VueFormWizard)
// Set the base URL of the API
ApiService.init(process.env.VUE_APP_BASE_URL)

Vue.prototype.$store = store

// If token exists set header
if (TokenService.getToken()) {
  ApiService.setHeader()
  ApiService.mountInterceptors()
}
if (localStorage.getItem('locale')) {
  i18n.locale = localStorage.getItem('locale')
} else {
  i18n.locale = 'en'
}

new Vue({
  router,
  store,
  i18n,
  async mounted () {
    if (TokenService.getToken()) {
      await UserService.getUser().then((response) => {
        store.commit('currentUser', response.data)
        return 200
      })
    }
  },
  render: h => h(App)
}).$mount('#app')
