<template>
  <div id="page-container"
       class="sidebar-o enable-page-overlay sidebar-dark side-scroll page-header-fixed">
    <!-- Sidebar -->
    <!--
        Sidebar Mini Mode - Display Helper classes

        Adding 'smini-hide' class to an element will make it invisible (opacity: 0) when the sidebar is in mini mode
        Adding 'smini-show' class to an element will make it visible (opacity: 1) when the sidebar is in mini mode
            If you would like to disable the transition animation, make sure to also add the 'no-transition' class to your element

        Adding 'smini-hidden' to an element will hide it when the sidebar is in mini mode
        Adding 'smini-visible' to an element will show it (display: inline-block) only when the sidebar is in mini mode
        Adding 'smini-visible-block' to an element will show it (display: block) only when the sidebar is in mini mode
    -->
    <navigation></navigation>
    <!-- END Sidebar -->

    <!-- Header -->
    <topbar></topbar>
    <!-- END Header -->

    <!-- Main Container -->
    <main id="main-container">
      <transition name="fade">
        <loader v-if="this.$store.state.loading"></loader>
      </transition>
      <router-view></router-view>
    </main>
    <!-- END Main Container -->
    <bottombar></bottombar>
  </div>

</template>

<script>
import Topbar from '@/components/layout/Topbar'
import Navigation from '@/components/layout/Navigation'
import Bottombar from '@/components/layout/Bottombar'
import Loader from '@/components/layout/Loader'
import App from '../oneui/app'

export default {
  name: 'Default',
  components: {
    Topbar,
    Navigation,
    Bottombar,
    Loader
  },
  created () {
    // eslint-disable-next-line no-undef
    jQuery(() => {
      // Create a new instance of App
      window.One = new App()
    })
  },
  methods: {
    makeRoute (route) {
      route.params = this.$route.params
      return route
    }
  }
}
</script>

<style lang="scss">
  .fade-leave-active {
    transition-property: opacity;
    transition-duration: .1s;
  }

  .fade-enter, .fade-leave-active {
    opacity: 0
  }

  #main-container {
    position: relative;
  }
</style>
