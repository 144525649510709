<template>
   <div class="form-group" :class="[ to.inputType, {'formly-has-value': model[field.key], 'formly-has-focus': form[field.key].$active, 'has-error': hasError}]">
      <label v-if="to.label" v-text="$t('formly.fields.' + to.label)" :for="field.key"></label>
      <input v-if="to.onlyDate" class="datepicker" type="date" :id="field.key" v-model="model[field.key]">
      <input v-else class="datepicker" type="datetime-local" :id="field.key" v-model="model[field.key]">
      <span class="help-block form-text text-danger"
            v-if="form.$errors[field.key] && form.$errors[field.key].length > 0"
            v-text="$t('validation.' + field.key + '.' + form.$errors[field.key][0])" />
      <!-- <error-display v-if="form.$errors[field.key].lenght > 0" :form="form" :field="field.key"></error-display> -->
   </div>
</template>

<script>
import baseField from 'vue-formly-bootstrap/src/fields/baseField'
export default {
  mixins: [baseField]
}
</script>
