var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row pb-3"},[_c('div',{staticClass:"col-sm-6 text-center"},[_c('img',{staticClass:"pb-4 mw-100",attrs:{"src":_vm.imageUrl || '/no-image.png',"alt":""}}),(_vm.imageUrl)?_c('button',{staticClass:"btn btn-danger w-100 mb-3",attrs:{"type":"button"},on:{"click":function($event){return _vm.deleteImage()}}},[_vm._v(_vm._s(_vm.$t('delete'))+" ")]):_vm._e()]),_c('div',{staticClass:"col-sm-6"},[(_vm.image.src)?_c('cropper',{ref:"cropper",staticClass:"upload-example-cropper",attrs:{"stencil-component":_vm.circle ? 'circle-stencil' : 'rectangle-stencil',"canvas":{
        height: this.height,
        width: this.width
      },"stencil-props":{
        handlers: {},
        movable: false,
        scalable: false,
        aspectRatio: this.aspectRatio,
      },"resize-image":{
        adjustStencil: false
      },"default-size":_vm.defaultSize,"image-restriction":"stencil","src":_vm.image.src}}):_vm._e(),_c('div',{staticClass:"file-drop-area",style:({display: _vm.image.src ? 'none' : 'flex'})},[(!_vm.loading)?_c('span',{staticClass:"fake-btn"},[_vm._v(_vm._s(_vm.$t('file.chooseImage')))]):_vm._e(),(!_vm.loading)?_c('span',{staticClass:"file-msg"},[_vm._v(_vm._s(_vm.$t('file.orDragAndDrop')))]):_vm._e(),(_vm.loading)?_c('span',{staticClass:"upload-cog"},[_c('i',{staticClass:"fa fa-cog fa-spin"})]):_vm._e(),(!_vm.loading)?_c('input',{ref:"file",staticClass:"file-input",attrs:{"type":"file","accept":"image/*"},on:{"change":function($event){return _vm.loadImage($event)}}}):_vm._e()]),_c('div',{staticClass:"mt-3"},[(_vm.image.src)?_c('button',{staticClass:"btn btn-success mb-3 mr-3",attrs:{"type":"button"},on:{"click":_vm.uploadImage}},[_vm._v(" "+_vm._s(_vm.$t('file.cropAndSave'))+" ")]):_vm._e(),(_vm.image.src)?_c('button',{staticClass:"btn btn-warning mb-3 mr-3",attrs:{"type":"button"},on:{"click":_vm.reset}},[_c('i',{staticClass:"fas fa-undo"})]):_vm._e()])],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }