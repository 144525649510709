<template>
  <div class="about">
    <page-header :title="$t('client.edit')" :desc="model.name">
      <li class="breadcrumb-item"><router-link :to="{name:'clients.index'}">{{$tc('client.name', 2)}}</router-link></li>
      <li class="breadcrumb-item">{{model.name}}</li>
    </page-header>
    <page-content>
      <ntm-block v-if="environment !== 'production'">
        <button @click="setCurrentLocalhostTenant()" :class="{'btn-success': model.isCurrentLocalhostTenant, 'btn-info': !model.isCurrentLocalhostTenant}" class="btn mb-3">Set current localhost tenant</button>
      </ntm-block>

      <form @submit.prevent="onSubmit()">
        <ntm-block>
          <formly-form :form="form" :model="model" :fields="fields"></formly-form>
          <button class="btn btn-success push-15">{{$t('save')}}</button>
        </ntm-block>
      </form>

      <ntm-block title="Trenutno stanje">
        <formly-form :form="filterForm" :model="filterModel" :fields="filterFields"></formly-form>
        <div class="block block-rounded block-link-pop text-center" >
          <div class="block-content block-content-full ratio ratio-1x1">
            <div class="d-flex justify-content-center align-items-center">
              <div>
                <div class="fs-2 fw-bold text-body-color-dark">{{ gamesCount }}</div>
                <div class="fs-sm fw-semibold mt-1 text-uppercase text-muted">{{ $tc('game.played', 2) }}</div>
              </div>
            </div>
          </div>
          <button v-if="gamesCount" :disabled="exporting" @click="download()" class="btn btn-info">Download csv <i v-if="exporting" class="fa fa-spin fa-cog ml-2"></i></button>
        </div>
      </ntm-block>
    </page-content>
  </div>
</template>

<script>
import fields from './formDefinition'
import ClientService from '../../services/client.service'
import router from '@/router'
import store from '@/store'
import ExportService from '@/services/export.service'

export default {
  // required properties, field, form, model
  data () {
    return {
      filterForm: {},
      filterModel: {},
      filterFields: [
        {
          key: 'from',
          type: 'datepicker',
          format: 'YYYY-MM-DD',
          templateOptions: {
            label: 'from',
            onlyDate: true
          }
        },
        {
          key: 'to',
          type: 'datepicker',
          format: 'YYYY-MM-DD',
          templateOptions: {
            label: 'to',
            onlyDate: true
          }
        },
        {
          key: 'schedules',
          type: 'ntm-select',
          options: [],
          multiple: true,
          templateOptions: {
            label: 'league'
          }
        }
      ],
      form: {},
      model: {},
      gamesCount: 0,
      fields: fields,
      exporting: false
    }
  },
  beforeRouteEnter (to, from, next) {
    ClientService.show(to.params.uuid).then((response) => {
      next(vm => vm.setData(response.data))
    })
  },
  beforeRouteUpdate (to, from, next) {
    ClientService.show(to.params.uuid).then((response) => {
      this.setData(response.data)
      store.commit('loaded')
    })
  },
  computed: {
    environment () {
      return process.env.VUE_APP_ENV
    }
  },
  watch: {
    filterModel: {
      handler () {
        this.getGameCount()
      },
      immediate: false,
      deep: true
    }
  },
  methods: {
    download () {
      this.exporting = true
      let filename = 'games-played'

      if (this.filterModel.from) {
        filename += `-from-${this.filterModel.from}`
      }

      if (this.filterModel.to) {
        filename += `-to-${this.filterModel.to}`
      }

      ExportService.gamesPlayedExport(this.$route.params.uuid, this.filterModel).then((response) => {
        console.log(response)
        const fileURL = window.URL.createObjectURL(new Blob([response.data]))
        const fileLink = document.createElement('a')
        fileLink.href = fileURL
        fileLink.setAttribute('download', `${filename}.csv`)
        document.body.appendChild(fileLink)
        fileLink.click()
      }).finally(() => {
        this.exporting = false
      })
    },
    getGameCount () {
      ClientService.countGames(this.$route.params.uuid, this.filterModel).then((res) => {
        console.log(res.data)
        this.gamesCount = res.data.gamesCount
        store.commit('loaded')
      })
    },
    setCurrentLocalhostTenant () {
      ClientService.setCurrentLocalhostTenant(this.model.uuid).then(() => {
        this.model.isCurrentLocalhostTenant = true
      })
    },
    setData (data) {
      this.model = data
      const schedulesOptions = this.filterFields.find((f) => f.key === 'schedules')
      schedulesOptions.options = data.schedules
    },
    updateLogo () {
      ClientService.show(this.$route.params.uuid).then((response) => {
        this.uploading = false
        this.model.logo = response.data.logo
      })
    },
    onSubmit () {
      ClientService.update(this.model.uuid, this.model).then((response) => {
        router.push({ name: 'clients.index' })
      }).catch((err) => {
        this.form.$errors = err.response.data.errors
      })
    }
  }
}
</script>
