import Vue from 'vue'
import VueI18n from 'vue-i18n'

Vue.use(VueI18n)

function loadLocaleMessages () {
  const locales = require.context('../../lm-locales', true, /[A-Za-z0-9-_,\s]+\.json$/i)
  const messages = {}
  locales.keys().forEach(key => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i)
    if (matched && matched.length > 1) {
      const locale = matched[1]
      messages[locale] = locales(key)
    }
  })

  return messages
}

const dateFormats = {
  time: {
    hour: '2-digit',
    minute: '2-digit',
    hour12: false
  },
  short: {
    year: 'numeric',
    month: 'long',
    day: 'numeric'
  },
  dateLong: {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    weekday: 'long'
  }
}

export default new VueI18n({
  locale: process.env.VUE_APP_I18N_LOCALE || 'en',
  fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || 'en',
  messages: loadLocaleMessages(),
  datetimeFormats: {
    en: dateFormats,
    sr: dateFormats
  }
})
