<template>
    <aside class="aside-form font-size-sm" :class="{open: param}">
      <!-- Side Header -->
      <div class="content-header border-bottom">
        <!-- User Avatar -->
        <a v-if="avatar" class="img-link mr-1" href="javascript:void(0)">
          <img class="img-avatar img-avatar32" :src="avatar" alt="">
        </a>
        <!-- END User Avatar -->

        <!-- User Info -->
        <div class="ml-2">
          <a class="link-fx text-dark font-w600" href="javascript:void(0)">{{ title }}</a>
        </div>
        <!-- END User Info -->

        <!-- Close Side Overlay -->
        <!-- Layout API, functionality initialized in Template._uiApiLayout() -->
        <a class="ml-auto btn btn-sm btn-dual" href="javascript:void(0)" @click="param = null">
          <i class="fa fa-fw fa-times text-danger"></i>
        </a>
        <!-- END Close Side Overlay -->
      </div>
      <!-- END Side Header -->

      <!-- Side Content -->
      <div class="content-side">
        <slot></slot>
      </div>
      <!-- END Side Content -->
    </aside>
</template>
<script>
import { useRouteQuery } from '../../use/useRouteQuery'

export default {
  name: 'ntmSidebar',
  props: {
    name: {
      type: String,
      default: null
    },
    title: {
      type: String,
      default: null
    },
    avatar: {
      type: String,
      default: null
    }
  },
  setup (props, { emit }) {
    const param = useRouteQuery(props.name)

    function close () {
      param.value = null
      emit('close')
    }

    return {
      param,
      close
    }
  },
  methods: {
    clickOutside () {
      this.close()
    }
  }
}
</script>
<style>
.aside-form {
  z-index: 9999999;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  background-color: #fff;
  overflow-y: auto;
  -webkit-transform: translateX(100%) translateY(0) translateZ(0);
  transform: translateX(100%) translateY(0) translateZ(0);
  transition: 0.5s ease;
  opacity: 0;
  -webkit-overflow-scrolling: touch;
  box-shadow: 10px 0px 15px 5px #000;
  will-change: transform;
  width: 320px;
}

.aside-form.open {
  transform: translateX(0) translateY(0) translateZ(0);
  opacity: 1;
}

.aside-form .content-header {
  padding-left: 1.25rem;
  padding-right: 1.25rem;
}
</style>
