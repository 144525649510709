<template>
  <div class="client_index">
    <page-header :title="$tc('client.name', 2)">
    </page-header>
    <page-content>
      <ntm-block>
        <template slot="options">
          <input type="text" class="form-control" :placeholder="$tc('search')"  v-model="search">
<!--          <button @click="createPage()" type="button" class="btn btn-sm btn-success">{{$t('action.addNew')}}</button>-->
<!--          <button @click="exportAll()" type="button" :title="$t('action.export')" class="btn btn-sm btn-warning"> <i class="fa fa-file" aria-hidden="true"></i></button>-->
        </template>
        <div class="table-responsive">
          <ntm-table :show-columns="showColumns" :items="items" url="api/clients" index="clients" :actions="true">
            <template slot="actions" slot-scope="{row}">
              <router-link :to="{ name: 'clients.edit', params: {uuid: row.uuid }}" class="btn btn-sm btn-primary">
                <i class="fa fa-fw fa-pencil-alt"></i>
              </router-link>
            </template>
          </ntm-table>
        </div>
      </ntm-block>
      <!-- END Full Table -->
    </page-content>
  </div>
</template>
<script>
import ClientService from '@/services/client.service'
import _ from 'lodash'
import router from '@/router'
import basicIndexMixin from '@/mixins/basic.index.mixin'
export default {
  mixins: [basicIndexMixin],
  computed: {
    showColumns () {
      if (this.environment === 'production') {
        return [
          {
            name: 'name',
            prop: 'name'
          },
          {
            name: '',
            prop: 'gamesCount'
          }
        ]
      }

      return [
        {
          name: 'name',
          prop: 'name'
        },
        {
          name: '',
          prop: 'gamesCount'
        },
        {
          name: 'localhost',
          prop: 'isCurrentLocalhostTenant',
          type: 'bool'
        }
      ]
    }
  },
  beforeRouteEnter (to, from, next) {
    ClientService.index('').then((response) => {
      next(vm => vm.setData(response.data))
    })
  },
  methods: {
    fetchData: _.debounce(function () {
      ClientService.index(this.search).then((response) => {
        this.setData(response.data)
      })
    }, 200),
    createPage () {
      router.push({ name: 'clients.create' })
    }
  }
}
</script>
