<template>
  <div class="form-group formly-datepicker"
       :class="[ to.inputType, {'formly-has-value': model[field.key], 'formly-has-focus': form[field.key].$active, 'has-error': hasError}]">
    <label v-if="to.label" v-text="$t('formly.fields.' + to.label)"></label>
    <multiselect v-if="isObject" :track-by="field.trackBy || 'uuid'" @change="changed"
                 @search-change="getOptions"
                 :multiple="field.multiple ? field.multiple : false"
                 :custom-label="to.customLabel ? to.customLabel : customLabel"
                 :placeholder="$tc('select.option', field.multiple ? 2 : 1)"
                 :close-on-select="!field.multiple"
                 v-model="model[field.key]"
                 :options="options"></multiselect>
    <multiselect v-else @change="changed"
                 @search-change="getOptions"
                 :multiple="field.multiple ? field.multiple : false"
                 :custom-label="to.customLabel ? to.customLabel : customLabel"
                 :placeholder="$tc('select.option', field.multiple ? 2 : 1)"
                 :close-on-select="!field.multiple"
                 v-model="model[field.key]"
                 :options="options"></multiselect>
     <error-display :form="form" :field="field.key"></error-display>
  </div>
</template>

<script>
import ApiService from '@/services/api.service'
import baseField from 'vue-formly-bootstrap/src/fields/baseField'
import Multiselect from 'vue-multiselect'
import ErrorDisplay from '../formly-bootstrap/components/errorDisplay'
import VueI18n from '@/i18n'

export default {
  mixins: [baseField],
  data () {
    return {
      options: []
    }
  },
  components: {
    Multiselect,
    ErrorDisplay
  },
  mounted () {
    if (this.field.options) {
      this.options = this.field.options
    } else {
      this.getOptions()
    }
  },
  watch: {
    'field.options': 'updateOptions'
  },
  methods: {
    customLabel (o) {
      if (this.isObject) {
        if (this.to.translated) {
          return `${o.name ? VueI18n.t('select.options.' + o) : ''}`
        }

        return `${o.name ? o.name : ''}`
      } else {
        if (this.to.translated) {
          return VueI18n.t('select.options.' + o)
        }

        return o
      }
    },
    updateOptions () {
      this.options = this.field.options
    },
    changed () {
    },
    getOptions (q) {
      const search = q || ''
      if (this.field.optionsApi && !this.field.options) {
        ApiService.get(`api/v1/admin/search/${this.field.optionsApi}?search=${search}`)
          .then((response) => {
            this.options = response.data
          })
      }
    }
  },
  computed: {
    isObject () {
      if (typeof this.options[0] === 'string') {
        return false
      }
      return true
    }
  }
}
</script>
