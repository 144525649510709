import Index from '@/views/clients/Index'
import Create from '@/views/clients/Create'
import Edit from '@/views/clients/Edit'

export default [
  {
    path: '/clients',
    name: 'clients.index',
    component: Index
  },
  {
    path: '/clients/create',
    name: 'clients.create',
    component: Create,
    meta: {
      title: 'Create client',
      breadcrumb: [
        {
          name: 'Clients',
          route: {
            name: 'clients.index'
          }
        },
        {
          name: 'Create'
        }
      ]
    }
  },
  {
    path: '/clients/:uuid',
    name: 'clients.edit',
    component: Edit,
    meta: {
      title: 'Edit clients',
      breadcrumb: [
        {
          name: 'Clients',
          route: {
            name: 'clients.index'
          }
        },
        {
          name: 'Edit'
        }
      ]
    }
  }
]
