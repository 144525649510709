<template>
  <div class="home">
  </div>
</template>

<script>
// @ is an alias to /src

export default {
  name: 'home',
  mounted () {
    this.$router.push({
      name: 'dashboard'
    })
  }
}
</script>
