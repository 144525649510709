<template>
  <div class="home">
    <page-content>
      <h1>Dashboard</h1>
    </page-content>
  </div>
</template>

<script>

export default {
  data () {
    return {
      model: {}
    }
  },
  beforeRouteEnter (to, from, next) {
    next()
  },
  methods: {
    setData (data) {
      this.model = data
    }
  }
}
</script>
