<template>
  <div class="form-group formly-datepicker"
       :class="[ to.inputType, {'formly-has-value': model[field.key], 'formly-has-focus': form[field.key].$active, 'has-error': hasError}]">
      <div class="flex d-inline-flex align-items-center">
        <v-swatches
          class="mr-2"
          v-model="model[field.key]"
          show-fallback
          fallback-input-type="color"
          popover-x="right"
        ></v-swatches>
        <label v-text="$t('formly.fields.' + to.label)"></label>
      </div>
    <error-display :form="form" :field="field.key"></error-display>
  </div>
</template>

<script>
import baseField from 'vue-formly-bootstrap/src/fields/baseField'
import VSwatches from 'vue-swatches'
// background: linear-gradient(15.92deg, #FFA767 16.87%, #EE6B33 94.68%);
export default {
  components: { VSwatches },
  mixins: [baseField]
}
</script>
