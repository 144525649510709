<template>
  <div class="row">
    <div class="col-sm-3">
      <img class="w-100 pb-4" :src="imageUrl" alt="">
      <button v-if="imageUrl" @click="deleteImage()" class="btn btn-danger w-100">{{$t('delete')}}</button>
    </div>
    <div class="col-sm-9">
      <vue-dropzone @vdropzone-sending="sendingEvent" @vdropzone-success="fetchImage" class="mb-3" id="dropzone"
                    :options="dropzoneOptions"></vue-dropzone>
    </div>
  </div>
</template>

<script>
import { TokenService } from '../../services/storage.service'
import vue2Dropzone from 'vue2-dropzone'
import MediaService from '../../services/media.service'

export default {
  components: {
    vueDropzone: vue2Dropzone
  },
  props: {
    entityType: {
      type: String,
      default: ''
    },
    entityUuid: {
      type: String,
      default: ''
    },
    collection: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      imageUrl: '',
      dropzoneOptions: {
        url: `${process.env.VUE_APP_BASE_URL}api/v1/admin/media/upload`,
        headers: {
          Authorization: `Bearer ${TokenService.getToken()}`
        }
      }
    }
  },
  mounted () {
    this.fetchImage()
  },
  methods: {
    fetchImage () {
      MediaService.getFirstImageByType(this.entityType, this.entityUuid, this.collection).then((res) => {
        this.imageUrl = res.data
      })
    },
    deleteImage () {
      MediaService.deleteByType(this.entityType, this.entityUuid, this.collection).then(() => {
        this.fetchImage()
      })
    },
    sendingEvent (file, xhr, formData) {
      formData.append('type', this.collection)
      formData.append('uuid', this.entityUuid)
      formData.append('assetType', this.entityType)
    }
  }
}
</script>
