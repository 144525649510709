<template>
  <div class="form-group formly-datepicker"
       :class="[ to.inputType, {'formly-has-value': model[field.key], 'formly-has-focus': form[field.key].$active, 'has-error': hasError}]">
    <label v-if="to.label" v-text="$t('formly.fields.' + to.label)"></label>
    <div>
      <div class="flex d-inline-flex align-items-center">
        <v-swatches
          class="mr-2"
          v-model="color1"
          show-fallback
          fallback-input-type="color"
          popover-x="right"
        ></v-swatches>
        <v-swatches
          class="mr-5"
          v-model="color2"
          show-fallback
          fallback-input-type="color"
          popover-x="right"
        ></v-swatches>
        <a class="btn mb-2 border-0" :style="{background: color, width: '150px'}">Test</a>
      </div>
    </div>

    <error-display :form="form" :field="field.key"></error-display>
  </div>
</template>

<script>
import baseField from 'vue-formly-bootstrap/src/fields/baseField'
import VSwatches from 'vue-swatches'
export default {
  components: { VSwatches },
  mixins: [baseField],
  data () {
    return {
      color1: '#DDE6E8',
      color2: '#BDC3C8',
      colorTest: ''
    }
  },
  updated () {
    this.color = this.model[this.field.key]
  },
  methods: {
    colorChanged (value) {
      this.model[this.field.key] = value
    }
  },
  watch: {
    color: 'colorChanged'
  },
  computed: {
    color: {
      get () {
        return `linear-gradient(15.92deg, ${this.color1} 16.87%, ${this.color2} 94.68%)`
      },
      set (value) {
        if (value) {
          const linearSplitString = value.split(' ')
          this.color1 = linearSplitString[1]
          this.color2 = linearSplitString[3]
        }
      }
    }
  }
}
</script>
